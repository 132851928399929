import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { StoreContext } from '../../store/provider';
import './styles.css';

interface Iprops {
  gestao?: boolean;
}

const Menu: React.FC<Iprops> = ({ gestao }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuActive, setMenuActive] = useState<any>();

  const { setUser, user } = useContext(StoreContext);

  const history = useHistory();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const sections = document.querySelectorAll('section');

      const positions = Array.from(sections).map(
        (section: any) => section.offsetTop
      );

      if (window.scrollY <= positions[0]) {
        setMenuActive(0);
        return;
      }

      const activePosition = positions.findIndex(
        (position, index) =>
          !positions[index + 1] ||
          (window.scrollY + 120 >= position &&
            window.scrollY + 120 < positions[index + 1])
      );

      setMenuActive(activePosition);
    });
  }, []);

  const handlerLogout = () => {
    localStorage.removeItem('token');

    setUser({
      token: ''
    });
  };

  const navigateToLogin = () => {
    history.push('login');
  };

  return (
    <div className={!menuOpened ? 'menu' : 'menu menu-opened'}>
      <button className="menu-button-open" onClick={() => setMenuOpened(true)}>
        <FiMenu />
      </button>

      {menuOpened && (
        <button
          className="menu-button-close"
          onClick={() => setMenuOpened(false)}
        >
          <AiOutlineClose />
        </button>
      )}

      <nav>
        <ul>
          <li>
            <a
              className={`link ${menuActive === 0 ? 'link--active' : ''}`}
              onClick={() => setMenuOpened(false)}
              href="#home"
            >
              Home
            </a>
          </li>
          <li>
            <a
              className={`link ${menuActive === 1 ? 'link--active' : ''}`}
              onClick={() => setMenuOpened(false)}
              href="#quem-somos"
            >
              Quem somos?
            </a>
          </li>
          <li>
            <a
              className={`link ${menuActive === 2 ? 'link--active' : ''}`}
              onClick={() => setMenuOpened(false)}
              href="#equipe"
            >
              Nossa Equipe
            </a>
          </li>
          <li>
            <a
              className={`link ${menuActive === 3 ? 'link--active' : ''}`}
              onClick={() => setMenuOpened(false)}
              href="#servicos"
            >
              Serviços
            </a>
          </li>
          <li>
            <a
              className={`link ${menuActive === 4 ? 'link--active' : ''}`}
              onClick={() => setMenuOpened(false)}
              href="#midia"
            >
              Mídia
            </a>
          </li>
          <li>
            <a
              className={`link ${menuActive === 5 ? 'link--active' : ''}`}
              onClick={() => setMenuOpened(false)}
              href="#contato"
            >
              Contato
            </a>
          </li>

          <li>
            {user.token && gestao ? (
              <button className="link" onClick={handlerLogout}>
                Sair
              </button>
            ) : (
              <button className="link" onClick={navigateToLogin}>
                Login
              </button>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
