import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../service/api';

interface IProps {
  children: any;
}

interface IUser {
  token: string;
}

interface IContextProps {
  user: {
    token: string;
  };
  setUser: any;
}

export const StoreContext = createContext({
  user: {
    token: ''
  }
} as IContextProps);

const StoreProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<IUser>({
    token: ''
  });

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    setUser({
      token: JSON.parse(token)
    });

    api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }, []);

  useEffect(() => {
    const interceptors = api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          toast.error('Sessão expirada. Por favor logue novamente');

          localStorage.removeItem('token');

          setTimeout(() => {
            setUser({
              token: ''
            });
          }, 1000);
        }

        throw error;
      }
    );

    return function cleanup() {
      api.interceptors.response.eject(interceptors);
    };
  });

  return (
    <StoreContext.Provider value={{ user, setUser }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
