import React from 'react';
import logo from '../../assets/images/logo_paixao_1.png';
import './styles.css';

const Header: React.FC = () => {
  return (
    <div className="header">
      <div className="logo">
        <img src={logo} alt="logo-paixao-advocacia" />
      </div>
    </div>
  );
};

export default Header;
