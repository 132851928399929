import React, { useContext, useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { StoreContext } from '../../store/provider';
import '../../styles/card.css';
import '../../styles/gestao.css';
import IAboutProps from '../../Types/About';
import ICardProps from '../../Types/Card';
import './styles.css';

interface IProps {
  gestao?: boolean;
}

const QuemSomos: React.FC<IProps> = ({ gestao }) => {
  const [id, setId] = useState<number | undefined>();
  const [headerTitle, setHeaderTitle] = useState('');
  const [header, setHeader] = useState('');
  const [footerTitle, setFooterTitle] = useState('');
  const [footer, setFooter] = useState('');
  const [cards, setCards] = useState<ICardProps[]>();
  const [modoEdicao, setModoEdicao] = useState(false);

  const [responseData, setResponseData] = useState<IAboutProps>();

  const { user } = useContext(StoreContext);

  useEffect(() => {
    const getData = async () => {
      try {
        api.defaults.headers.Authorization = `Bearer ${user.token}`;

        const response = await api.get('/about');

        const data: IAboutProps = response.data;

        setHeaderTitle(data.headerTitle);
        setHeader(data.header);
        setFooterTitle(data.footerTitle);
        setFooter(data.footer);
        setCards(data.cards.map((item) => ({ ...item })));
        setId(data.id);

        setResponseData(data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [user.token]);

  const handlerSubmit = async (event: any) => {
    event.preventDefault();

    try {
      api.defaults.headers.Authorization = `Bearer ${user.token}`;

      const data = {
        id,
        headerTitle,
        header,
        footerTitle,
        footer,
        cards
      };

      const response = await api.put('/about', data);

      setResponseData(response.data);

      setModoEdicao(false);

      toast.success('Dados alterados com sucesso');
    } catch (err) {
      console.log(err);
      if (err?.response?.status !== 401) {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  };

  const handlerCancel = () => {
    setHeaderTitle(responseData?.headerTitle || '');
    setHeader(responseData?.header || '');
    setFooterTitle(responseData?.footerTitle || '');
    setFooter(responseData?.footer || '');
    setCards(responseData?.cards.map((item: any) => ({ ...item })) || []);

    setModoEdicao(false);
  };

  const addCard = () => {
    const newCards = cards ? [...cards] : [];

    newCards.push({
      title: '',
      text: ''
    });

    setCards(newCards);
  };

  const removeCard = (index: number) => {
    const newCards = cards ? [...cards] : [];

    newCards.splice(index, 1);

    setCards(newCards);
  };

  return (
    <section className="quem-somos" id="quem-somos">
      {!modoEdicao && gestao && (
        <div className="edit-button">
          <button type="button" onClick={() => setModoEdicao(true)}>
            Editar
          </button>
        </div>
      )}

      {!modoEdicao ? (
        <>
          <div
            className="section-title"
            dangerouslySetInnerHTML={{ __html: headerTitle }}
          />

          <div dangerouslySetInnerHTML={{ __html: header }} />

          <div className="card-list">
            {cards &&
              cards.map((card, index) => (
                <div key={index} className="card">
                  <div
                    className="card-title"
                    dangerouslySetInnerHTML={{ __html: card.title }}
                  />

                  <div
                    className="card-text"
                    dangerouslySetInnerHTML={{ __html: card.text }}
                  />
                </div>
              ))}
          </div>

          <div className="diferencial">
            <div
              className="section-title"
              dangerouslySetInnerHTML={{ __html: footerTitle }}
            />

            <div dangerouslySetInnerHTML={{ __html: footer }} />
          </div>
        </>
      ) : (
        <div>
          <form onSubmit={handlerSubmit} action="">
            <ReactQuill
              id="headerTitle"
              className={`input-editor ${true && 'input-editor--disabled'}`}
              theme="snow"
              value={headerTitle}
              onChange={setHeaderTitle}
            />

            <ReactQuill
              className="input-editor"
              theme="snow"
              value={header}
              onChange={setHeader}
            />

            <ReactQuill
              className="input-editor"
              theme="snow"
              value={footerTitle}
              onChange={setFooterTitle}
            />
            <ReactQuill
              className="input-editor"
              theme="snow"
              value={footer}
              onChange={setFooter}
            />

            <div className="card-gestao-list">
              {cards?.map((card, index) => (
                <div key={index} className="card-gestao">
                  <div className="card-gestao-header">
                    <h6>Card {index + 1}</h6>

                    <button
                      type="button"
                      onClick={() => {
                        removeCard(index);
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </div>

                  <div className="input-editor">
                    <label htmlFor={`card-title-${index}`}>Título</label>
                    <ReactQuill
                      id={`card-title-${index}`}
                      theme="snow"
                      value={card.title}
                      onChange={(value) => {
                        const newCards = [...cards];

                        newCards[index].title = value;

                        setCards(newCards);
                      }}
                    />
                  </div>

                  <div className="input-editor">
                    <label htmlFor={`card-text-${index}`}>Conteúdo</label>
                    <ReactQuill
                      id={`card-text-${index}`}
                      theme="snow"
                      value={card.text}
                      onChange={(value) => {
                        const newCards = [...cards];

                        newCards[index].text = value;

                        setCards(newCards);
                      }}
                    />
                  </div>
                </div>
              ))}

              <button
                type="button"
                className="button-add-card"
                onClick={addCard}
              >
                Adicionar Card
              </button>
            </div>

            <div className="buttons">
              <button
                type="button"
                className="Cancelar"
                onClick={handlerCancel}
              >
                Cancelar
              </button>
              <button type="submit" className="Salvar">
                Salvar
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default QuemSomos;
