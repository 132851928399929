import React, { useContext, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { StoreContext } from '../../store/provider';
import '../../styles/gestao.css';
import IBannerProps from '../../Types/Banner';
import './styles.css';

interface IProps {
  gestao?: boolean;
}

const CarouselComponent: React.FC<IProps> = ({ gestao }) => {
  const [responseData, setResponseData] = useState<IBannerProps[]>();
  const [bannerList, setBannerList] = useState<IBannerProps[]>([]);
  const [bannerListChanged, setBannerListChanged] = useState<any[]>([]);

  const [modoEdicao, setModoEdicao] = useState(false);
  const { user } = useContext(StoreContext);

  useEffect(() => {
    const getData = async () => {
      try {
        api.defaults.headers.Authorization = `Bearer ${user.token}`;

        const response = await api.get('/banners');

        const data: IBannerProps[] = response.data;

        setResponseData(data);
        setBannerList(data.map((banner) => ({ ...banner })));
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [user.token]);

  const handlerCancel = (newBannerList?: IBannerProps[]) => {
    if (newBannerList) {
      setBannerList(newBannerList?.map((item: any) => ({ ...item })) || []);
    } else {
      setBannerList(responseData?.map((item: any) => ({ ...item })) || []);
    }
    setBannerListChanged([]);
    setModoEdicao(false);
  };

  const handlerSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const data = new FormData();

      bannerListChanged.forEach((bannerChanged) => {
        if (bannerChanged.file) {
          data.append('file', bannerChanged.file);
        }

        if (bannerChanged.bannerId) {
          data.append('changedBanners', bannerChanged.bannerId);
        }
      });

      bannerList
        .filter((banner) => !banner.id)
        .forEach((newBanner) => {
          data.append('file', newBanner.file);
        });

      api.defaults.headers.Authorization = `Bearer ${user.token}`;

      const response = await api.put('/banners', data);

      setResponseData(response.data?.map((item: any) => ({ ...item })) || []);

      handlerCancel(response.data);

      toast.success('Banners alterados com sucesso');
    } catch (err) {
      if (err?.response?.status !== 401) {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
      console.log(err);
    }
  };

  const removeBanner = (index: number) => {
    if (bannerList[index].id) {
      setBannerListChanged([
        ...bannerListChanged,
        { bannerId: bannerList[index].id }
      ]);
    }

    const newBannerList = bannerList.map((item) => ({ ...item }));
    newBannerList.splice(index, 1);
    setBannerList(newBannerList);
  };

  const addBanner = () => {
    const newBannerList = [
      ...bannerList,
      {
        photo: {
          name: '',
          path: '',
          url: ''
        }
      }
    ];

    setBannerList(newBannerList);
  };

  const onFileChange = (event: any, banner: IBannerProps, index: number) => {
    if (!event?.target?.files[0]) {
      return;
    }

    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    if (!banner.id) {
      const newBannerList = [...bannerList];

      newBannerList[index] = {
        file,
        photo: {
          name: '',
          path: '',
          url
        }
      };

      setBannerList(newBannerList);
      return;
    }

    if (!bannerList) {
      return;
    }

    const newBannerList = bannerList.map((item, i) => {
      if (item.id === banner.id) {
        return {
          id: item.id,
          photo: {
            ...item.photo,
            url
          }
        };
      } else {
        return { ...item };
      }
    });

    setBannerListChanged([...bannerListChanged, { file, bannerId: banner.id }]);

    setBannerList(newBannerList);
  };

  return (
    <section className="home" id="home">
      {!modoEdicao && gestao && (
        <button
          className="button-edition"
          type="button"
          onClick={() => setModoEdicao(true)}
        >
          Editar
        </button>
      )}

      {!modoEdicao ? (
        <Carousel>
          {bannerList?.map((banner, index) => (
            <Carousel.Item key={index}>
              <img src={banner.photo.url} alt="Banner" />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div className="gestao">
          <form onSubmit={handlerSubmit}>
            <div className="gestao-banner-list">
              {bannerList?.map((banner, index) => (
                <div key={index} className="banner-card">
                  <div className="card-gestao-header">
                    <h6>Banner {index + 1}</h6>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          removeBanner(index);
                        }}
                      >
                        <FaTrashAlt />
                      </button>
                      <label htmlFor={`banner-${index}`}>
                        <FaPencilAlt />
                      </label>
                    </div>
                  </div>

                  <label
                    htmlFor={`banner-${index}`}
                    style={{
                      backgroundImage: `url(${banner.photo.url})`
                    }}
                  >
                    <input
                      name={`banner-${index}`}
                      id={`banner-${index}`}
                      type="file"
                      onChange={(event) => onFileChange(event, banner, index)}
                    />
                  </label>
                </div>
              ))}
            </div>

            <div className="buttons">
              <button
                type="button"
                className="button-add-card"
                onClick={addBanner}
              >
                Adicionar Banner
              </button>
              <button
                type="button"
                className="Cancelar"
                onClick={() => handlerCancel()}
              >
                Cancelar
              </button>
              <button type="submit" className="Salvar">
                Salvar
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default CarouselComponent;
