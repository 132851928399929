import React from 'react';
import ITeamMemberProps from '../../Types/TeamMember';
import './styles.css';

interface IProps {
  teamMember: ITeamMemberProps | null;
  handleCloseModal: () => void;
}

const ModalTeamMemberDetails: React.FC<IProps> = ({
  teamMember,
  handleCloseModal
}) => {
  return !!teamMember ? (
    <div className="modal-team-member">
      <div className="content">
        <div className="header">
          <button type="button" onClick={handleCloseModal}>
            X
          </button>
        </div>

        <img src={teamMember?.photo.url} alt={teamMember?.name} />

        <h5>{teamMember?.name}</h5>

        <p>{teamMember?.description}</p>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default ModalTeamMemberDetails;
