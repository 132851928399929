import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { StoreContext } from '../store/provider';

interface IProps {
  component: any;
  exact: boolean;
  isPrivate: boolean;
  path: string;
}

const PrivateRoutes: React.FC<IProps> = ({ component: Component, ...rest }) => {
  const { user } = useContext(StoreContext);

  const { token } = user;

  return (
    <Route
      render={() =>
        token ? <Component {...rest} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoutes;
