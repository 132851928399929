import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { StoreContext } from '../../store/provider';
import IContact from '../../Types/Contact';
import Loading from '../DefaultFieldForms/Loading';
import './styles.css';

const contactEmailInitialData = {
  phone: '',
  email: '',
  name: '',
  description: ''
};

interface IProps {
  gestao?: boolean;
}

const Contato: React.FC<IProps> = ({ gestao }) => {
  const [id, setId] = useState<number | undefined>();
  const [modoEdicao, setModoEdicao] = useState(false);
  const [googleMapsIframe, setGoogleMapsIframe] = useState('');
  const [responseData, setResponseData] = useState<IContact>();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [locationLink, setLocationLink] = useState('');

  const [loading, setLoading] = useState(false);

  const [contactEmail, setContactEmail] = useState(contactEmailInitialData);

  const { user } = useContext(StoreContext);

  useEffect(() => {
    const getData = async () => {
      try {
        api.defaults.headers.Authorization = `Bearer ${user.token}`;

        const response = await api.get('/contact');

        const data: IContact = response.data;

        setResponseData(data);

        setAddress(data.address);
        setEmail(data.email);
        setGoogleMapsIframe(data.googleMapsIframe);
        setPhoneNumber(data.phoneNumber);
        setId(data.id);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [user.token]);

  useEffect(() => {
    const handlerGoogleMapsIframe = (iframe: string) => {
      if (modoEdicao) {
        return;
      }

      try {
        const srcLink = iframe
          .match(/src=('|")[^('|")]+/)![0]
          .replace(/src=|('|")/g, '');

        setLocationLink(srcLink);
      } catch (err) {
        setLocationLink('');
      }
    };

    if (googleMapsIframe) {
      handlerGoogleMapsIframe(googleMapsIframe);
    }
  }, [googleMapsIframe, modoEdicao]);

  const handlerSubmit = async (event: any) => {
    event.preventDefault();

    try {
      api.defaults.headers.Authorization = `Bearer ${user.token}`;

      const data = {
        id,
        googleMapsIframe: googleMapsIframe,
        address,
        email,
        phoneNumber
      };

      await api.put('/contact', data);

      setModoEdicao(false);

      toast.success('Dados alterados com sucesso');
    } catch (err) {
      console.log(err);
      if (err?.response?.status !== 401) {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  };

  const handlerCancel = () => {
    setAddress(responseData?.address || '');
    setGoogleMapsIframe(responseData?.googleMapsIframe || '');
    setEmail(responseData?.email || '');
    setPhoneNumber(responseData?.phoneNumber || '');
    setGoogleMapsIframe(responseData!.googleMapsIframe || '');
    setModoEdicao(false);
  };

  const onChange = (event: any) => {
    const { value, name } = event.target;

    setContactEmail({
      ...contactEmail,
      [name]: value
    });
  };

  const sendEmail = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      await api.post('/contact/email', contactEmail);

      setContactEmail(contactEmailInitialData);
      toast.success('E-mail enviado com sucesso');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Ocorreu um erro, tente novamente mais tarde');
      console.log(err);
    }
  };

  return (
    <section className="contato" id="contato">
      {!modoEdicao && gestao && (
        <div className="edit-button">
          <button type="button" onClick={() => setModoEdicao(true)}>
            Editar
          </button>
        </div>
      )}

      {!modoEdicao ? (
        <>
          <h3>Contato</h3>

          <div className="form">
            <form onSubmit={sendEmail} action="">
              <div className="input-area">
                <input
                  value={contactEmail.name}
                  type="text"
                  id="name"
                  name="name"
                  required
                  onChange={onChange}
                  placeholder=" "
                />
                <label htmlFor="name">Nome</label>
              </div>
              <div className="input-area">
                <input
                  value={contactEmail.email}
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={onChange}
                  placeholder=" "
                />
                <label htmlFor="email">E-mail</label>
              </div>
              <div className="input-area">
                <input
                  value={contactEmail.phone}
                  type="text"
                  id="phone"
                  name="phone"
                  required
                  onChange={onChange}
                  placeholder=" "
                />
                <label htmlFor="phone">Celular</label>
              </div>

              <div className="input-area">
                <textarea
                  value={contactEmail.description}
                  rows={5}
                  id="description"
                  name="description"
                  required
                  onChange={onChange}
                  placeholder=" "
                />
                <label htmlFor="description">Descrição</label>
              </div>

              <button type="submit" disabled={loading}>
                {loading ? (
                  <Loading
                    styles={{
                      width: 26,
                      height: 26,
                      borderWidth: 4,
                      borderTopColor: '#000'
                    }}
                  />
                ) : (
                  'Enviar'
                )}
              </button>
            </form>

            <div className="location">
              <iframe
                title="Localização"
                src={locationLink}
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
              ></iframe>
            </div>
          </div>

          <div className="footer">
            <div className="phone">
              <span>{phoneNumber}</span>
            </div>

            <div className="email">
              <h6>Paixão Advocacia e Consultoria Juridica</h6>
              <hr />
              <h6>{email}</h6>
            </div>

            <div className="address">
              <span>{address}</span>
            </div>
          </div>

          <div className="copyright">
            <h6>Draft Comunicação Visual</h6>
          </div>
        </>
      ) : (
        <div className="gestao">
          <form onSubmit={handlerSubmit} className="deafult-form">
            <div className="input-area">
              <textarea
                rows={5}
                id="video-link"
                required
                value={googleMapsIframe}
                onChange={(event) => {
                  const { value } = event.target;

                  setGoogleMapsIframe(value);
                }}
              />
              <label htmlFor="video-link">Línk da localização</label>
            </div>

            <div className="input-area">
              <input
                type="text"
                id="phoneNumber"
                required
                value={phoneNumber}
                onChange={(event) => {
                  const { value } = event.target;

                  setPhoneNumber(value);
                }}
              />
              <label htmlFor="phoneNumber">Número de telefone</label>
            </div>
            <div className="input-area">
              <input
                type="text"
                id="email"
                required
                value={email}
                onChange={(event) => {
                  const { value } = event.target;

                  setEmail(value);
                }}
              />
              <label htmlFor="phoneNumber">E-mail</label>
            </div>
            <div className="input-area">
              <input
                type="text"
                id="address"
                required
                value={address}
                onChange={(event) => {
                  const { value } = event.target;

                  setAddress(value);
                }}
              />
              <label htmlFor="address">Endereço</label>
            </div>

            <div className="buttons">
              <button
                type="button"
                className="Cancelar"
                onClick={handlerCancel}
              >
                Cancelar
              </button>
              <button type="submit" className="Salvar">
                Salvar
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default Contato;
