import React, { useContext, useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { StoreContext } from '../../store/provider';
import '../../styles/default-fields-form.css';
import '../../styles/gestao.css';
import IArticleProps from '../../Types/Article';
import IMidiaProps from '../../Types/Midia';
import './styles.css';

interface IProps {
  gestao?: boolean;
}

const Midia: React.FC<IProps> = ({ gestao }) => {
  const [id, setId] = useState<number | undefined>();
  const [articles, setArticles] = useState<IArticleProps[]>();
  const [videoLink, setVideoLink] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [modoEdicao, setModoEdicao] = useState(false);
  const [youtubeVideoIframe, setYoutubeVideoIframe] = useState('');
  const [responseData, setResponseData] = useState<IMidiaProps>();

  const { user } = useContext(StoreContext);

  useEffect(() => {
    articles?.forEach((article) => {
      const maxNumCharacters = 200;

      if (article.description.length <= maxNumCharacters) {
        return;
      }

      const descriptionLimited = article.description.substring(
        0,
        maxNumCharacters
      );

      article.description = descriptionLimited + '...';
    });
  }, [articles]);

  useEffect(() => {
    const getData = async () => {
      try {
        api.defaults.headers.Authorization = `Bearer ${user.token}`;

        const response = await api.get('/midia');

        const data: IMidiaProps = response.data;

        setResponseData(data);

        setArticles(data.articles.map((item) => ({ ...item })));
        setYoutubeVideoIframe(data.youtubeVideoIframe);

        setId(data.id);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [user.token]);

  useEffect(() => {
    const handlerVideoLink = (videoLink: string) => {
      if (modoEdicao) {
        return;
      }

      try {
        const srcLink = videoLink
          .match(/src=('|")[^('|")]+/)![0]
          .replace(/src=|('|")/g, '');

        const videoTitle = videoLink
          .match(/title=('|")[^('|")]+/)![0]
          .replace(/title=|('|")/g, '');

        setVideoLink(srcLink);
        setVideoTitle(videoTitle);
      } catch (err) {
        setVideoLink('');
        setVideoTitle('');
      }
    };

    if (youtubeVideoIframe) {
      handlerVideoLink(youtubeVideoIframe);
    }
  }, [youtubeVideoIframe, modoEdicao]);

  const next = () => {
    const list = document.querySelector('div.artigos ul');

    if (!list) {
      return;
    }

    list.scrollBy(0, -230);
  };

  const previous = () => {
    const list = document.querySelector('div.artigos ul');

    if (!list) {
      return;
    }

    list.scrollBy(0, 230);
  };

  const addArticle = () => {
    const newArticles = articles ? [...articles] : [];

    newArticles.push({
      title: '',
      description: '',
      link: ''
    });

    setArticles(newArticles);
  };

  const removeArticle = (index: number) => {
    const newArticles = articles ? [...articles] : [];

    newArticles.splice(index, 1);

    setArticles(newArticles);
  };

  const handlerSubmit = async (event: any) => {
    event.preventDefault();

    try {
      api.defaults.headers.Authorization = `Bearer ${user.token}`;

      const data = {
        id,
        youtubeVideoIframe,
        articles
      };

      const response = await api.put('/midia', data);

      setResponseData(response.data);

      setModoEdicao(false);

      toast.success('Dados alterados com sucesso');
    } catch (err) {
      console.log(err);
      if (err?.response?.status !== 401) {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  };

  const handlerCancel = () => {
    setArticles(responseData?.articles.map((item: any) => ({ ...item })) || []);
    setYoutubeVideoIframe(responseData?.youtubeVideoIframe || '');
    setModoEdicao(false);
  };

  return (
    <section className="midia" id="midia">
      <hr />

      {!modoEdicao && gestao && (
        <div className="edit-button">
          <button type="button" onClick={() => setModoEdicao(true)}>
            Editar
          </button>
        </div>
      )}

      {!modoEdicao ? (
        <>
          <h3>Mídia</h3>

          <div
            className={`content ${
              !(articles && articles?.length) ? 'content-center' : ''
            }`}
          >
            <div className="video">
              <iframe
                title={videoTitle}
                width="100%"
                height="100%"
                src={videoLink}
                style={{ border: 0 }}
                allowFullScreen
              ></iframe>
            </div>

            {articles && articles?.length && (
              <div className="artigos">
                {articles && articles?.length > 2 && (
                  <button className="arrow--up" onClick={next}>
                    <MdKeyboardArrowUp />
                  </button>
                )}

                <ul>
                  {articles &&
                    articles.map((article, index) => (
                      <li key={index}>
                        <div className="artigo-info">
                          <h4>{article.title}</h4>
                          <p>{article.description}</p>
                        </div>
                        <div className="artigo-download">
                          <a
                            target="_blank"
                            href={article.link}
                            rel="noreferrer"
                          >
                            Acessar
                          </a>
                        </div>
                      </li>
                    ))}
                </ul>

                {articles && articles?.length > 2 && (
                  <button className="arrow--down" onClick={previous}>
                    <MdKeyboardArrowDown />
                  </button>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="gestao">
          <form onSubmit={handlerSubmit} className="deafult-form">
            <div className="input-area">
              <textarea
                rows={5}
                id="video-link"
                required
                value={youtubeVideoIframe}
                onChange={(event) => {
                  const { value } = event.target;

                  setYoutubeVideoIframe(value);
                }}
              />
              <label htmlFor="video-link">Línk do vídeo</label>
            </div>

            <div className="gestao-article-list">
              {articles?.map((article, index) => (
                <div key={index} className="card-gestao">
                  <div className="card-gestao-header">
                    <h6>Artigo {index + 1}</h6>
                    <button
                      type="button"
                      onClick={() => {
                        removeArticle(index);
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </div>

                  <div className="input-area">
                    <input
                      type="text"
                      id="article-title-1"
                      required
                      value={article.title}
                      onChange={(event) => {
                        const newArticle = [...articles];

                        const { value } = event.target;

                        newArticle[index].title = value;

                        setArticles(newArticle);
                      }}
                    />
                    <label htmlFor="article-title-1">Título do artigo</label>
                  </div>
                  <div className="input-area">
                    <input
                      type="text"
                      id="article-description-1"
                      required
                      value={article.description}
                      onChange={(event) => {
                        const newArticle = [...articles];

                        const { value } = event.target;

                        newArticle[index].description = value;

                        setArticles(newArticle);
                      }}
                    />
                    <label htmlFor="article-description-1">
                      Resumo do artigo
                    </label>
                  </div>
                  <div className="input-area">
                    <input
                      type="text"
                      id="article-link-1"
                      required
                      value={article.link}
                      onChange={(event) => {
                        const newArticle = [...articles];

                        const { value } = event.target;

                        newArticle[index].link = value;

                        setArticles(newArticle);
                      }}
                    />
                    <label htmlFor="article-link-1">Link do artigo</label>
                  </div>
                </div>
              ))}

              <button
                type="button"
                className="button-add-card"
                onClick={addArticle}
              >
                Adicionar Artigo
              </button>
            </div>
            <div className="buttons">
              <button
                type="button"
                className="Cancelar"
                onClick={handlerCancel}
              >
                Cancelar
              </button>
              <button type="submit" className="Salvar">
                Salvar
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default Midia;
