import React from 'react';
import CarouselComponent from '../../components/Carousel';
import Contato from '../../components/Contato';
import Equipe from '../../components/Equipe';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Midia from '../../components/Midia';
import QuemSomos from '../../components/QuemSomos';
import Servicos from '../../components/Servicos';

const HomePage: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Menu />
      <CarouselComponent />
      <QuemSomos />
      <Equipe />
      <Servicos />
      <Midia />
      <Contato />
    </div>
  );
};

export default HomePage;
