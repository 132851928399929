import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from '../Pages/HomePage/Index';
import Login from '../Pages/Login';
import Management from '../Pages/Management';
import PrivateRoutes from './privateRoute';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />

      <Route path="/login" exact component={Login} />

      <PrivateRoutes path="/gestao" exact component={Management} isPrivate />

      {/* <Route component={Login} /> */}
    </Switch>
  );
}
