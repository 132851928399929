import React, { useEffect } from 'react';
import CarouselComponent from '../../components/Carousel';
import Contato from '../../components/Contato';
import Equipe from '../../components/Equipe';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Midia from '../../components/Midia';
import QuemSomos from '../../components/QuemSomos';
import Servicos from '../../components/Servicos';
import api from '../../service/api';

const Management: React.FC = () => {
  useEffect(() => {
    const verifyToken = async () => {
      api.get('/users/verify-token');
    };
    verifyToken();
  }, []);

  return (
    <div className="App">
      <Header />
      <Menu gestao />
      <CarouselComponent gestao />
      <QuemSomos gestao />
      <Equipe gestao />
      <Servicos gestao />
      <Midia gestao />
      <Contato gestao />
    </div>
  );
};
export default Management;
