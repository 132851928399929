import React, { useContext, useEffect, useState } from 'react';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../service/api';
import { StoreContext } from '../../store/provider';
import '../../styles/default-fields-form.css';
import '../../styles/gestao.css';
import ITeamMemberProps from '../../Types/TeamMember';
import ModalComponent from '../DefaultFieldForms/Modal';
import ModalTeamMemberDetails from '../ModalTeamMemberDetails';
import './styles.css';

interface IProps {
  gestao?: boolean;
}

const Equipe: React.FC<IProps> = ({ gestao }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lines, setLines] = useState<any[]>([]);
  const [removeFirstLine, setRemoveFirstLine] = useState(false);
  const [teamMembers, setTeamMembers] = useState<ITeamMemberProps[]>([]);
  const [responseData, setResponseData] = useState<ITeamMemberProps[]>([]);

  const [modoEdicao, setModoEdicao] = useState(false);
  const { user } = useContext(StoreContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);

  const [teamMemberDetails, setTeamMemberDetails] =
    useState<null | ITeamMemberProps>(null);

  const closeModal = () => {
    setModalVisible(false);
    setItemToDelete(null);
  };

  const openModal = (index: number) => {
    setModalVisible(true);
    setItemToDelete(index);
  };

  useEffect(() => {
    window.addEventListener('resize', function () {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const maxWidth = 1500;
    const memberSize = 300;
    const numberMembersLine = Math.floor(
      (windowWidth >= maxWidth ? maxWidth : windowWidth) / memberSize
    );

    const numberLines = Math.ceil(teamMembers.length / numberMembersLine);

    setRemoveFirstLine(numberLines * numberMembersLine !== teamMembers.length);

    setLines(new Array(numberLines).fill(null));
  }, [windowWidth, teamMembers.length]);

  useEffect(() => {
    const getData = async () => {
      try {
        api.defaults.headers.Authorization = `Bearer ${user.token}`;

        const response = await api.get('/teamMembers');

        const data: ITeamMemberProps[] = response.data;

        setResponseData(data.sort(sortTeamMember));
        setTeamMembers(
          data
            .map((item) => ({
              ...item,
              editing: false
            }))
            .sort(sortTeamMember)
        );
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [user.token]);

  const onFileChange = (
    event: any,
    member: ITeamMemberProps,
    index: number
  ) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    const newTeamMembers = [...teamMembers];

    newTeamMembers[index].file = file;
    newTeamMembers[index].photo.url = url;

    setTeamMembers(newTeamMembers);
  };

  const enableCardEditing = (index: number) => {
    const newTeamMembers = teamMembers.map((member, i) =>
      index === i ? { ...member, editing: true } : { ...member }
    );

    setTeamMembers(newTeamMembers);
  };

  const removeTeamMember = async () => {
    if (itemToDelete === null) {
      return;
    }

    if (teamMembers[itemToDelete].id) {
      try {
        api.defaults.headers.Authorization = `Bearer ${user.token}`;

        await api.delete(`/teamMembers/${teamMembers[itemToDelete].id}`);

        toast.success('Dados removidos com sucesso');
      } catch (err: any) {
        console.log(err);
        if (err?.response?.status !== 401) {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
        return;
      }
    }

    const newTeamMembers = teamMembers.map((item) => ({ ...item }));

    newTeamMembers.splice(itemToDelete, 1);

    setTeamMembers(newTeamMembers);
    closeModal();
  };

  const addTeamMember = () => {
    const newTeamMembers = [
      ...teamMembers,
      {
        name: '',
        description: '',
        editing: true,
        photo: {
          name: '',
          path: '',
          url: ''
        }
      }
    ];

    setTeamMembers(newTeamMembers);
  };

  const handlerCancel = (id: number | undefined, index: number) => {
    if (!id) {
      const newTeamMembers = teamMembers.map((item) => ({ ...item }));

      newTeamMembers.splice(index, 1);

      setTeamMembers(newTeamMembers);

      return;
    }

    const oldDataCard = responseData.findIndex((member) => member.id === id);

    const newTeamMembers = teamMembers.map((member) =>
      id === member.id ? { ...responseData[oldDataCard] } : { ...member }
    );

    setTeamMembers(newTeamMembers || []);
  };

  const handlerSubmit = async (index: number) => {
    try {
      const data = new FormData();

      if (teamMembers[index].file) {
        data.append('file', teamMembers[index].file);
      }

      if (teamMembers[index].id) {
        data.append('id', String(teamMembers[index].id));
      }
      data.append('name', teamMembers[index].name);
      data.append('position', teamMembers[index].position);
      data.append('description', teamMembers[index].description);

      api.defaults.headers.Authorization = `Bearer ${user.token}`;

      if (teamMembers[index].id) {
        const response = await api.put('/teamMembers', data);

        const newListResponse = [...teamMembers];
        newListResponse[index] = response.data;

        setResponseData(newListResponse);

        setTeamMembers(
          newListResponse.map((item: any) => ({ ...item, editing: false }))
        );

        toast.success('Dados alterados com sucesso');
      } else {
        const response = await api.post('/teamMembers', data);

        const newListResponse = [...teamMembers];
        newListResponse[index] = response.data;

        setResponseData(newListResponse);

        setTeamMembers(
          newListResponse.map((item: any) => ({ ...item, editing: false }))
        );

        toast.success('Dados cadastrados com sucesso');
      }
    } catch (err: any) {
      if (err?.response?.status !== 401) {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  };

  const handleCloseModalTeamMemberDetails = () => {
    setTeamMemberDetails(null);
  };

  const sortTeamMember = (a: ITeamMemberProps, b: ITeamMemberProps) => {
    if ((a.position || 1) > (b.position || 1)) {
      return 1;
    }
    if ((a.position || 1) < (b.position || 1)) {
      return -1;
    }
    return 0;
  };

  return (
    <section className="equipe" id="equipe">
      <div>
        {gestao &&
          (!modoEdicao ? (
            <button
              className="button-edition"
              type="button"
              onClick={() => setModoEdicao(true)}
            >
              Editar
            </button>
          ) : (
            <button
              type="button"
              className="button-edition"
              onClick={() => setModoEdicao(false)}
            >
              Visualizar
            </button>
          ))}
      </div>

      <h3>Nossa Equipe</h3>
      {!modoEdicao ? (
        <div className="team-list">
          <ul>
            {teamMembers.map((member, index) => (
              <li key={index}>
                <div
                  className="flip-card"
                  onClick={() => setTeamMemberDetails(member)}
                >
                  <img src={member.photo.url} alt="Membro da equipe" />
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h5>{member.name}</h5>
                    </div>
                    <div className="flip-card-back">
                      <h5>Clique e veja mais</h5>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className={`lines`}>
            {lines.map((line, index) => (
              <hr
                key={index}
                className={`line ${
                  index === 0 && removeFirstLine ? 'remove' : ''
                } line-${(index + 1) % 2 === 0 ? 'left' : 'right'}`}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="gestao">
          <form className="deafult-form">
            <div className="gestao-member-list">
              {teamMembers?.map((member, index) => (
                <div key={index} className="member-card">
                  {!member.editing && (
                    <div className="buttons">
                      <button type="button" onClick={() => openModal(index)}>
                        <FaTrashAlt />
                      </button>
                      <button
                        type="button"
                        onClick={() => enableCardEditing(index)}
                      >
                        <FaPencilAlt />
                      </button>
                    </div>
                  )}

                  <div className="input-file">
                    <label
                      htmlFor={`member-file-${index}`}
                      style={{
                        backgroundImage: `url(${member.photo.url})`,
                        cursor: !member.editing ? 'default' : 'pointer'
                      }}
                    >
                      <input
                        disabled={!member.editing}
                        name={`member-file-${index}`}
                        id={`member-file-${index}`}
                        type="file"
                        onChange={(event) => onFileChange(event, member, index)}
                      />
                    </label>
                  </div>

                  <div className="input-area">
                    <input
                      type="text"
                      id="name"
                      disabled={!member.editing}
                      required
                      value={member.name}
                      onChange={(value) => {
                        const newTeamMembers = [...teamMembers];
                        newTeamMembers[index].name = value.target.value;
                        setTeamMembers(newTeamMembers);
                      }}
                      placeholder=" "
                    />
                    <label htmlFor="name">Nome</label>
                  </div>
                  <div className="input-area">
                    <input
                      min={0}
                      type="number"
                      id="position"
                      disabled={!member.editing}
                      required
                      value={member.position}
                      onChange={(value) => {
                        const newTeamMembers = [...teamMembers];
                        newTeamMembers[index].position = value.target.value;
                        setTeamMembers(newTeamMembers);
                      }}
                      placeholder=" "
                    />
                    <label htmlFor="position">Posição</label>
                  </div>
                  <div className="input-area">
                    <textarea
                      id="description"
                      required
                      disabled={!member.editing}
                      value={member.description}
                      onChange={(value) => {
                        const newTeamMembers = [...teamMembers];
                        newTeamMembers[index].description = value.target.value;
                        setTeamMembers(newTeamMembers);
                      }}
                      placeholder=" "
                    />
                    <label htmlFor="description">Descrição</label>
                  </div>

                  {member.editing && (
                    <div className="buttons">
                      <button
                        type="button"
                        className="Cancelar"
                        onClick={() => handlerCancel(member.id, index)}
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        onClick={() => handlerSubmit(index)}
                        className="Salvar"
                      >
                        Salvar
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="buttons">
              <button
                type="button"
                className="button-add-card"
                onClick={addTeamMember}
              >
                Adicionar Membro
              </button>
            </div>
            <ModalComponent
              modalVisible={modalVisible}
              handleCloseModal={closeModal}
              handerDelete={removeTeamMember}
            />
          </form>
        </div>
      )}
      <ModalTeamMemberDetails
        teamMember={teamMemberDetails}
        handleCloseModal={handleCloseModalTeamMemberDetails}
      />
    </section>
  );
};

export default Equipe;
