import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../assets/images/logo_paixao_br_1.png';
import Loading from '../../components/DefaultFieldForms/Loading';
import api from '../../service/api';
import { StoreContext } from '../../store/provider';
import './styles.css';

const initialState = {
  email: '',
  password: ''
};

const Login: React.FC = () => {
  const [values, setValues] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const { setUser, user } = useContext(StoreContext);

  const history = useHistory();

  useEffect(() => {
    if (user.token) {
      history.push('/gestao');
    }
  }, [user, history]);

  const onChange = (event: any) => {
    const { value, name } = event.target;

    setValues({
      ...values,
      [name]: value
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await api.post('/sessions', values);

      const { data } = response;

      localStorage.setItem('token', JSON.stringify(data.token));
      api.defaults.headers.Authorization = `Bearer ${data.token}`;

      setUser({
        token: data.token
      });
      setLoading(false);
    } catch (err) {
      setValues(initialState);
      setLoading(false);

      if (err?.response?.status !== 401) {
        toast.error('Usuário ou senha incorretos!');
      }
    }
  };

  return (
    <div className="login">
      <form onSubmit={onSubmit}>
        <img src={Logo} alt="Logotipo" />

        <div className="input-area">
          <input
            type="text"
            id="email"
            name="email"
            onChange={onChange}
            value={values.email}
            required
          />
          <label htmlFor="email">E-mail</label>
        </div>
        <div className="input-area">
          <input
            type="password"
            id="password"
            name="password"
            onChange={onChange}
            value={values.password}
            required
          />
          <label htmlFor="password">Senha</label>
        </div>

        <button type="submit" disabled={loading}>
          {loading ? (
            <Loading styles={{ width: 26, height: 26, borderWidth: 4 }} />
          ) : (
            'Acessar'
          )}
        </button>
      </form>
    </div>
  );
};

export default Login;
