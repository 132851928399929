import React, { CSSProperties } from 'react';
import './styles.css';

interface IProps {
  styles?: CSSProperties;
}

const Loading: React.FC<IProps> = ({ styles }) => {
  return <div className="Loading" style={styles} />;
};

export default Loading;
